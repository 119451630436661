import React from "react";

const DoubDots = ({ height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 110.773 112.956"
  >
    <g transform="translate(110.773) rotate(90)">
      <g transform="translate(69.11)">
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 68.251)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 54.601)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 40.951)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 27.3)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 13.65)"
        />
        <circle className="a" fill="#35353d" cx="1.202" cy="1.202" r="1.202" />
      </g>
      <g transform="translate(55.288)">
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 68.251)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 54.601)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 40.951)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 27.3)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 13.65)"
        />
        <circle className="a" fill="#35353d" cx="1.202" cy="1.202" r="1.202" />
      </g>
      <g transform="translate(41.466)">
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 68.251)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 54.601)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 40.951)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 27.3)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 13.65)"
        />
        <circle className="a" fill="#35353d" cx="1.202" cy="1.202" r="1.202" />
      </g>
      <g transform="translate(27.644)">
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 68.251)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 54.601)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 40.951)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 27.3)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 13.65)"
        />
        <circle className="a" fill="#35353d" cx="1.202" cy="1.202" r="1.202" />
      </g>
      <g transform="translate(13.822)">
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 68.251)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 54.601)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 40.951)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 27.3)"
        />
        <circle
          className="a"
          fill="#35353d"
          cx="1.202"
          cy="1.202"
          r="1.202"
          transform="translate(0 13.65)"
        />
        <circle className="a" fill="#35353d" cx="1.202" cy="1.202" r="1.202" />
      </g>
      <circle
        className="a"
        fill="#35353d"
        cx="1.202"
        cy="1.202"
        r="1.202"
        transform="translate(0 68.251)"
      />
      <circle
        className="a"
        fill="#35353d"
        cx="1.202"
        cy="1.202"
        r="1.202"
        transform="translate(0 54.601)"
      />
      <circle
        className="a"
        fill="#35353d"
        cx="1.202"
        cy="1.202"
        r="1.202"
        transform="translate(0 40.951)"
      />
      <circle
        className="a"
        fill="#35353d"
        cx="1.202"
        cy="1.202"
        r="1.202"
        transform="translate(0 27.3)"
      />
      <circle
        className="a"
        fill="#35353d"
        cx="1.202"
        cy="1.202"
        r="1.202"
        transform="translate(0 13.65)"
      />
      <circle className="a" fill="#35353d" cx="1.202" cy="1.202" r="1.202" />
      <g transform="translate(41.443 40.118)">
        <g transform="translate(69.11)">
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 68.251)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 54.601)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 40.951)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 27.301)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 13.65)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
          />
        </g>
        <g transform="translate(55.288)">
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 68.251)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 54.601)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 40.951)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 27.301)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 13.65)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
          />
        </g>
        <g transform="translate(41.466)">
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 68.251)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 54.601)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 40.951)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 27.301)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 13.65)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
          />
        </g>
        <g transform="translate(27.644 40.951)">
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 27.301)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 13.65)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
          />
        </g>
        <g transform="translate(13.822 40.951)">
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 27.301)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 13.65)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
          />
        </g>
        <g transform="translate(0 40.951)">
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 27.301)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
            transform="translate(0 13.65)"
          />
          <circle
            className="b"
            fill="#dfdfdf"
            cx="1.202"
            cy="1.202"
            r="1.202"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DoubDots;
